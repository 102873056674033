.investment-table {
  align-self: center;
  margin-left: 5px;
  max-width: none;
}

.contribution-insidetable {
  align-self: left;
  padding-left: 25px !important;
  width: fit-content;
}

:root {
  /* Deferredcomp.morganstanleyatwork.com Color Palette */
  --balances: #2196f3;
  --balances-light: #e3f2fd;
  --balances-dark: $deferral-balance-1;
  --distributions: #f44336;
  --distributions-light: #ffebee;
  --distributions-dark: #b71c1c;
  --investments: #4caf50;
  --investments-light: #e8f5e9;
  --investments-dark: #1b5e20;
  --contributions: #ff9800;
  --contributions-light: #fff3e0;
  --contributions-dark: #e65100;
}

.card-summary {
  border-top: 0px;
  margin-bottom: 0px;
}

.card-bg-summary {
  background-color: $deferral-background;
  border-bottom: none;
  padding: 0px;
}

.table-responsive-summaryBalance {
  display: block;
  width: 60%;
  overflow-x: auto;
}

.balances-button {
  &.active {
    background-color: $deferral-balance-1;
  }
}

.investment-button {
  &.active {
    background-color: $deferral-investment-1;
  }
}

.btn-contributions {
  visibility: hidden;
}

.contributions-button {
  &.active {
    background-color: $deferral-contribution-1;
  }
}

.distributions-button {
  &.active {
    background-color: $deferral-distribution-2;
  }
}

.pinfo-button {
  &.active {
    background-color: $deferral-pinfo-3;
  }
}

.profile-button {
  &.active {
    background-color: $deferral-profile-3;
  }
}

.enrollment-button {
  &.active {
    background-color: $deferral-enrollment-1;
  }
}

.balances-button2 {
  &.active {
    background-color: $deferral-balance-4a !important;
    .nav-label {
      color: $topmenu-active-text;
    }
  }
  &:hover {
    background-color: $deferral-balance-1a !important;
    .nav-label {
      color: $deferral-background;
    }
  }
}

.investment-button2 {
  &.active {
    background-color: $deferral-investment-4a !important;
    .nav-label {
      color: $topmenu-active-text;
    }
  }
  &:hover {
    background-color: $deferral-investment-1a !important;
    .nav-label {
      color: $deferral-background;
    }
  }
}

.contributions-button2 {
  &.active {
    background-color: $deferral-contribution-4a !important;
    .nav-label {
      color: $topmenu-active-text;
    }
  }
  &:hover {
    background-color: $deferral-contribution-1a !important;
    .nav-label {
      color: $deferral-background;
    }
  }
}

.distributions-button2 {
  &.active {
    background-color: $deferral-distribution-4a !important;
    .nav-label {
      color: $topmenu-active-text;
    }
  }
  &:hover {
    background-color: $deferral-distribution-1a !important;
    .nav-label {
      color: $deferral-background;
    }
  }
}

.pinfo-button2 {
  &.active {
    background-color: $deferral-pinfo-6a !important;
    .nav-label {
      color: $topmenu-active-text;
    }
  }
  &:hover {
    background-color: $deferral-pinfo-3a !important;
    .nav-label {
      color: $deferral-background;
    }
  }
}

.profile-button2 {
  &.active {
    background-color: $deferral-profile-6a !important;
    .nav-label {
      color: $topmenu-active-text;
    }
  }
  &:hover {
    background-color: $deferral-profile-3a !important;
    .nav-label {
      color: $deferral-background;
    }
  }
}

.enrollment-button2 {
  &.active {
    background-color: $deferral-enrollment-4a !important;
    .nav-label {
      color: $topmenu-active-text;
    }
  }
  &:hover {
    background-color: $deferral-enrollment-1a !important;
    .nav-label {
      color: $deferral-background;
    }
  }
}

.card-bg {
  background-color: $deferral-background-2;
}

/* Balances Areas: Blue */
.bg-balances {
  font-size: 1rem;
  font-weight: bold;
  border-color: $deferral-balance-4 !important;
  color: $deferral-text !important;
  background-color: $deferral-balance-4 !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-balance-4 !important;
  }
}

.bg-balances-light {
  background-color: transparent;
}

.card-bg-balances-main {
  margin-bottom: 0px;
  border-top: solid;
  border-top-color: $deferral-balance-1a;
  border-top-width: 1px;
  //padding-top: 5px;
}

.card-bg-balances {
  background-color: $deferral-background-2;
  border-bottom: none;
  padding-top: 10px;
  padding-bottom: 5px;
}

.card-bg-balances-messages {
  background-color: $deferral-background-2;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.card-bg-balances-options {
  background-color: $deferral-background-2;
  //padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 35px;
}

.bg-balances-dark {
  background-color: $deferral-balance-1;
}

.btn-balance {
  margin-top: 0px !important;
  padding-top: 0px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  font-size: $secondary-button-font-size;
  font-weight: 600;
  border-color: $deferral-button !important;
  color: $deferral-button !important;
  background-color: $deferral-background !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-button !important;
  }
}

.text-balances {
  color: $deferral-text;
  &.active {
    color: $topmenu-active-text;
  }
}
.text-balances-light {
  color: var(--balances-light) !important;
}

.text-balances-dark {
  color: $deferral-balance-1 !important;
}

/* Investments Areas: Red */
.bg-investments {
  font-size: 1rem;
  font-weight: bold;
  border-color: $deferral-balance-4 !important;
  color: $deferral-text !important;
  background-color: $deferral-balance-4 !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-balance-4 !important;
  }
}

.bg-investments-light {
  background-color: transparent;
}

.card-bg-investments-main {
  margin-bottom: 0px;
  border-top: solid;
  border-top-color: $deferral-investment-1a;
  border-top-width: 1px;
  //padding-top: 5px;
}

.card-bg-investments {
  background-color: $deferral-investment-4;
  border-bottom: none;
}

.card-bg-investments-messages {
  background-color: $deferral-background-2;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.card-bg-investments-options {
  background-color: $deferral-background-2;
  //padding-top: 5px;
  padding-bottom: 10px;
}

.card-bg-investments-options-2 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.bg-investments-dark {
  background-color: var(--investments-dark) !important;
}

.card.bg-investments {
  background-color: $deferral-investment-7;
  border-color: $deferral-background-2;
}

.btn-investment {
  margin-top: 0px !important;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  font-size: $secondary-button-font-size;
  font-weight: 600;
  border-color: $deferral-investment-4 !important;
  color: $deferral-text !important;
  background-color: $deferral-investment-4 !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-investment-4a !important;
  }
}

.text-investments {
  color: $deferral-text;
  //font-size: 0.8rem !important;
  &.active {
    color: $topmenu-active-text;
  }
}

.text-investments-light {
  color: var(--investments-light) !important;
}

.text-investments-dark {
  color: $deferral-text;
}

.investment-title {
  font-size: 1rem;
}

/* Contributions Areas: Green */
.bg-contributions {
  background-color: var(--contributions) !important;
}

.bg-contributions-light {
  background-color: transparent;
}

.bg-contributions-dark {
  background-color: var(--contributions-dark) !important;
}

.card-bg-contributions-main {
  margin-bottom: 0px;
  border-top: solid;
  border-top-color: $deferral-contribution-1a;
  border-top-width: 1px;
  //padding-top: 5px;
  padding-bottom: 5px;
}

.card-bg-contributions {
  background-color: $deferral-background-2;
  border-bottom: none;
}

.card-bg-contributions-messages {
  background-color: $deferral-background-2;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.card-bg-contributions-options {
  background-color: $deferral-background-2;
}

.btn-contribution {
  //  border-radius: 5px;
  margin-top: 0px !important;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  font-size: $secondary-button-font-size;
  font-weight: 600;
  border-color: $deferral-contribution-4 !important;
  color: $deferral-text !important;
  background-color: $deferral-contribution-4 !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-contribution-4a !important;
  }
}

.row-contributions {
  line-height: 1;
  width: 100%;
}

.text-contributions {
  color: $deferral-text;
  &.active {
    color: $topmenu-active-text;
  }
}

.text-section {
  font-size: large;
  font-weight: 600;
}

.text-contributions-light {
  color: var(--contributions-light) !important;
}

.text-contributions-dark {
  color: var(--contributions-dark) !important;
}

.contribution-title {
  padding-left: 30px;
}

.contribution-row {
  // text-align: -webkit-center;
  display: inline;
}

.contribution-row-history {
  text-align: -webkit-center;
  display: inline;
}

/* Distributions Areas: Yellow */
.bg-distributions {
  background-color: var(--distributions) !important;
}

.bg-distributions-light {
  background-color: transparent;
}

.bg-distributions-dark {
  background-color: var(--distributions-dark) !important;
}

.card-bg-distributions-main {
  margin-bottom: 0px;
  border-top: solid;
  border-top-color: $deferral-distribution-1a;
  border-top-width: 1px;
  //padding-top: 5px;
}

.card-bg-distributions {
  background-color: $deferral-background-2;
  border-bottom: none;
}

.card-bg-distributions-messages {
  background-color: $deferral-background-2;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.card-bg-distributions-options {
  background-color: $deferral-background-2;
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: left;
}

.btn-distribution {
  margin-top: 0px !important;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  font-size: $secondary-button-font-size;
  font-weight: 600;
  border-color: $deferral-distribution-5 !important;
  color: $deferral-text !important;
  background-color: $deferral-distribution-5 !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-distribution-5a !important;
  }
}

.text-distributions {
  color: $deferral-text;
  &.active {
    color: $topmenu-active-text;
  }
}

.text-distributions-light {
  color: var(--distributions-light) !important;
}

.text-distributions-dark {
  color: var(--distributions-dark) !important;
}

/* Reports Areas: Yellow */
.bg-pinfo {
  background-color: var(--pinfo) !important;
}

.bg-pinfo-light {
  background-color: transparent;
}

.bg-pinfo-dark {
  background-color: var(--pinfo-dark) !important;
}

.card-bg-pinfo-main {
  margin-bottom: 0px;
  border-top: solid;
  border-top-color: $deferral-pinfo-1a;
  border-top-width: 1px;
  //padding-top: 5px;
}

.card-bg-pinfo {
  background-color: $deferral-pinfo-6;
  border-bottom: none;
}

.card-bg-pinfo-messages {
  background-color: $deferral-background-2;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.card-bg-pinfo-options {
  background-color: $deferral-background-2;
}

.btn-pinfo {
  margin-top: 0px !important;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  font-size: $secondary-button-font-size;
  font-weight: 600;
  border-color: $deferral-pinfo-6 !important;
  color: $deferral-text !important;
  background-color: $deferral-pinfo-6 !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-pinfo-6a !important;
  }
}

.text-pinfo {
  color: $deferral-text;
  &.active {
    color: $topmenu-active-text;
  }
}

.text-pinfo-light {
  color: var(--pinfo-light) !important;
}

.text-pinfo-dark {
  color: var(--pinfo-dark) !important;
}

/* General Areas: Yellow */
.bg-profile {
  background-color: var(--profile) !important;
}

.bg-profile-light {
  background-color: transparent;
}

.bg-profile-dark {
  background-color: var(--profile-dark) !important;
}

.card-bg-profile-main {
  margin-bottom: 0px;
  border-top: solid;
  border-top-color: $deferral-profile-3a;
  border-top-width: 1px;
  //padding-top: 5px;
}

.card-bg-profile {
  background-color: $deferral-profile-6;
  border-bottom: none;
}

.card-bg-profile-messages {
  background-color: $deferral-background-2;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.card-bg-profile-options {
  background-color: $deferral-background-2;
}

.btn-profile {
  margin-top: 0px !important;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  font-size: $secondary-button-font-size;
  font-weight: 600;
  border-color: $deferral-profile-6 !important;
  color: $deferral-text !important;
  background-color: $deferral-profile-6 !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-profile-6a !important;
  }
}

.text-profile {
  color: $deferral-text;
  &.active {
    color: $topmenu-active-text;
  }
}

.text-profile-light {
  color: var(--profile-light) !important;
}

.text-profile-dark {
  color: var(--profile-dark) !important;
}

/* General Areas: Yellow */
.bg-enrollment {
  background-color: var(--enrollment) !important;
}

.bg-enrollment-light {
  background-color: transparent;
}

.bg-enrollment-dark {
  background-color: var(--enrollment-dark) !important;
}

.card-enrollment-menu {
  margin-left: -10px;
  margin-right: 0px;
}

.card-bg-enrollment-main {
  margin-bottom: 0px;
  border-top: solid;
  border-top-color: $deferral-enrollment-1a;
  border-top-width: 1px;
}

.card-bg-enrollment {
  padding-top: 0.375rem;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  background-color: $deferral-background;
  border-bottom: none;
}

.card-bg-enrollment-messages {
  background-color: $deferral-background-2;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}

.card-bg-enrollment-options {
  background-color: $deferral-background-2;
}

.btn-enrollment {
  font-size: 1rem;
  font-weight: bold;
  border-color: $deferral-enrollment-4 !important;
  color: $deferral-text !important;
  background-color: $deferral-enrollment-4 !important;
  box-shadow: none !important;
  &.active {
    color: $topmenu-active-text !important;
    background-color: $deferral-enrollment-4a !important;
  }
}

.text-enrollment {
  color: $deferral-text;
  &.active {
    color: $topmenu-active-text;
  }
}

.text-enrollment-light {
  color: var(--enrollment-light) !important;
}

.text-enrollment-dark {
  color: var(--enrollment-dark) !important;
}

.boxEnrollment {
  //font-family: "Segoe UI";
  height: 36px;
  background-color: $deferral-background-3;
  color: $deferral-enrollment-button1;
  font-size: 1rem;
  padding: 6px 6px;
  text-align: center;
  position: relative;
  margin: 12px;
  border: 0;
  float: center;
  border-radius: 5px;
  white-space: nowrap;
  // max-width: fit-content;
  box-shadow: none !important;
  border-color: $deferral-background !important;
  &.enabled {
    font-weight: 600;
    color: $topmenu-active-text;
    background-color: $deferral-enrollment-button;
    &:hover {
      font-weight: 1200;
      //color: $palette-white;
    }
  }
  &.disabled {
    color: $palette-gray3 !important;
    background-color: $palette-gray2 !important;
  }
  &:active {
    color: $palette-gray3 !important;
    background-color: $deferral-enrollment-button2 !important;
  }
  &.pre {
    background-color: $deferral-enrollment-button2;
    color: $palette-gray3;
    &:hover {
      text-decoration: none;
      // color: $palette-gray3;

      cursor: pointer;
    }
  }

  &.arrow-right {
    &:after {
      border-radius: 5px;
      content: " ";
      position: absolute;
      right: -16px;
      top: -1px;
      border-top: 19px solid transparent;
      border-right: none;
      border-left: 20px solid $deferral-background-3;
      border-bottom: 17px solid transparent;
    }
    &.pre {
      &:after {
        border-left: 20px solid $deferral-enrollment-button2;
      }
    }
    &.enabled {
      &:after {
        border-left: 20px solid $deferral-enrollment-button;
      }
    }
  }
}
