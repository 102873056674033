// Palette
$font-family: graphik, sans-serif;
$secondary-button-font-size: small;
$top-menu-font-size: medium;
$submenu-font-size: 0.8rem;
$palette-white: #ffffff !default;
$palette-gray: #f6f6f6 !default;
$palette-gray2: #f2f4f8 !default;
$palette-gray3: #6e6e7a !default;

$palette-black: #000000 !default;
$palette-text: #187aba !default;
$palette-button: #187aba !default;
$palette-button-save: green !default;
$palette-button-cancel: red !default;
$palette-icon: #3b7ebb !default;
$palette-border: #e1e6ef !default;
$palette-link: blue !default;
$palette-group-title: #187aba !default;

$palette-button-save-ms: #187aba !default;

$palette-primary-1: #006eb2 !default;
$palette-primary-2: #3d7dbb !default;
$palette-primary-3: #5c8cc3 !default;
$palette-primary-4: #769bcd !default;
$palette-primary-5: #8eabd4 !default;
$palette-primary-6: #a5bbdd !default;
$palette-primary-7: #bccce5 !default;
$palette-primary-8: #d2ddee !default;
$palette-primary-9: #e9eef7 !default;

$palette-primary-dark-1: #081435 !default;
$palette-primary-dark-2: #2c2f4b !default;
$palette-primary-dark-3: #4d4d62 !default;
$palette-primary-dark-4: #6e6e7a !default;
$palette-primary-dark-5: #919093 !default;
$palette-primary-dark-6: #b4b4ac !default;
$palette-primary-dark-7: #d9d9c6 !default;

$palette-secondary-7-1: #ffffff !default;
$palette-secondary-7-2: #ffffff !default;
$palette-secondary-7-3: #ffffff !default;
$palette-secondary-7-4: #ffffff !default;
$palette-secondary-7-5: #ffffff !default;
$palette-secondary-7-6: #ffffff !default;
$palette-secondary-7-7: #ffffff !default;

$palette-secondary-6-1: #ffffff !default;
$palette-secondary-6-2: #ffffff !default;
$palette-secondary-6-3: #ffffff !default;
$palette-secondary-6-4: #ffffff !default;
$palette-secondary-6-5: #ffffff !default;
$palette-secondary-6-6: #ffffff !default;
$palette-secondary-6-7: #ffffff !default;

$palette-secondary-2-1: #ffffff !default;
$palette-secondary-2-2: #ffffff !default;
$palette-secondary-2-3: #ffffff !default;
$palette-secondary-2-4: #ffffff !default;
$palette-secondary-2-5: #ffffff !default;
$palette-secondary-2-6: #ffffff !default;
$palette-secondary-2-7: #ffffff !default;

$palette-secondary-4-1: #ffffff !default;
$palette-secondary-4-2: #ffffff !default;
$palette-secondary-4-3: #ffffff !default;
$palette-secondary-4-4: #ffffff !default;
$palette-secondary-4-5: #ffffff !default;
$palette-secondary-4-6: #ffffff !default;
$palette-secondary-4-7: #ffffff !default;

$palette-secondary-3-1: #ffffff !default;
$palette-secondary-3-2: #ffffff !default;
$palette-secondary-3-3: #ffffff !default;
$palette-secondary-3-4: #ffffff !default;
$palette-secondary-3-5: #ffffff !default;
$palette-secondary-3-6: #ffffff !default;
$palette-secondary-3-7: #ffffff !default;

$palette-secondary-5-1: #ffffff !default;
$palette-secondary-5-2: #ffffff !default;
$palette-secondary-5-3: #ffffff !default;
$palette-secondary-5-4: #ffffff !default;
$palette-secondary-5-5: #ffffff !default;
$palette-secondary-5-6: #ffffff !default;
$palette-secondary-5-7: #ffffff !default;

// $palette-secondary-7-1: #ffeb3b !default;
// $palette-secondary-7-2: #ffee58 !default;
// $palette-secondary-7-3: #ffee58 !default;
// $palette-secondary-7-4: #fff176 !default;
// $palette-secondary-7-5: #fff59d !default;
// $palette-secondary-7-6: #fff9c4 !default;
// $palette-secondary-7-7: #fffde7 !default;

// $palette-secondary-7-1: #d81b60 !default;
// $palette-secondary-7-2: #e91e63 !default;
// $palette-secondary-7-3: #ec407a !default;
// $palette-secondary-7-4: #f06292 !default;
// $palette-secondary-7-5: #f48fb1 !default;
// $palette-secondary-7-6: #f8bbd0 !default;
// $palette-secondary-7-7: #fcc4ec !default;

$palette-secondary-1-1: #ffffff !default;
$palette-secondary-1-2: #ffffff !default;
$palette-secondary-1-3: #ffffff !default;
$palette-secondary-1-4: #ffffff !default;
$palette-secondary-1-5: #ffffff !default;
$palette-secondary-1-6: #ffffff !default;
$palette-secondary-1-7: #ffffff !default;

//properties
$topmenu-active-background: #f0f0f0;
$topmenu-active-text: #187aba;
$deferral-background: $palette-white;
$deferral-background-footer: #f6f6f6;
$deferral-background-2: $palette-gray;
$deferral-background-3: $palette-gray2;
$deferral-background-4: $palette-white;

$deferral-modal-radius: 15px;
$deferral-header-bck: $palette-text;
$deferral-header-text: white;
$deferral-text: rgba(31, 45, 49, 0.5);
$deferral-text2: #187aba;
$deferral-grid-title-sort: #187aba;
$deferral-grid-title-text: #4d4f53 !default;
$deferral-grid-title-bkg: #e6e6e6 !default;
$deferral-button: $palette-button;
$deferral-button-save: $palette-button-save;
$deferral-button-cancel: $palette-button-cancel;
$deferral-button-bck: #3b7ebb11 !default;
$deferral-button-save-bck: #00800011 !default;
$deferral-button-cancel-bck: #ff000011 !default;
$deferral-icon: $palette-icon;
$deferral-border: $palette-primary-1;
$deferral-menu: $palette-primary-dark-1;
$deferral-text-menu: $palette-primary-dark-1;
//$deferral-text-menu: $palette-text;

$ms-button-save: $palette-button-save-ms;

$deferral-balance-1: $palette-secondary-3-1;
$deferral-balance-1a: $palette-text;
$deferral-balance-2: $palette-secondary-3-2;
$deferral-balance-3: $palette-secondary-3-3;
$deferral-balance-4: $palette-secondary-3-4;
$deferral-balance-4a: $topmenu-active-background;
$deferral-balance-5: $palette-secondary-3-5;
$deferral-balance-6: $palette-secondary-3-6;
$deferral-balance-7: $palette-secondary-3-7;

$deferral-investment-1: $palette-secondary-1-1;
$deferral-investment-1a: $palette-text;
$deferral-investment-2: $palette-secondary-1-2;
$deferral-investment-3: $palette-secondary-1-3;
$deferral-investment-4: $palette-secondary-1-4;
$deferral-investment-4a: $topmenu-active-background;
$deferral-investment-5: $palette-secondary-1-5;
$deferral-investment-6: $palette-secondary-1-6;
$deferral-investment-7: $palette-secondary-1-7;

$deferral-contribution-1: $palette-secondary-4-1;
$deferral-contribution-1a: $palette-text;
$deferral-contribution-2: $palette-secondary-4-2;
$deferral-contribution-3: $palette-secondary-4-3;
$deferral-contribution-4: $palette-secondary-4-4;
$deferral-contribution-4a: $topmenu-active-background;
$deferral-contribution-5: $palette-secondary-4-5;
$deferral-contribution-6: $palette-secondary-4-6;
$deferral-contribution-7: $palette-secondary-4-7;

$deferral-distribution-1: $palette-secondary-2-1;
$deferral-distribution-1a: $palette-text;
$deferral-distribution-2: $palette-secondary-2-2;
$deferral-distribution-3: $palette-secondary-2-3;
$deferral-distribution-4: $palette-secondary-2-4;
$deferral-distribution-4a: $topmenu-active-background;
$deferral-distribution-5: $palette-secondary-2-5;
$deferral-distribution-5a: $topmenu-active-background;
$deferral-distribution-6: $palette-secondary-2-6;
$deferral-distribution-7: $palette-secondary-2-7;

$deferral-pinfo-1: $palette-secondary-5-1;
$deferral-pinfo-1a: $palette-text;
$deferral-pinfo-2: $palette-secondary-5-2;
$deferral-pinfo-3: $palette-secondary-5-3;
$deferral-pinfo-3a: $palette-text;
$deferral-pinfo-4: $palette-secondary-5-4;
$deferral-pinfo-5: $palette-secondary-5-5;
$deferral-pinfo-6: $palette-secondary-5-6;
$deferral-pinfo-6a: $topmenu-active-background;
$deferral-pinfo-7: $palette-secondary-5-7;

$deferral-profile-1: $palette-secondary-6-1;
$deferral-profile-2: $palette-secondary-6-2;
$deferral-profile-3: $palette-secondary-6-3;
$deferral-profile-3a: $palette-text;
$deferral-profile-4: $palette-secondary-6-4;
$deferral-profile-5: $palette-secondary-6-5;
$deferral-profile-6: $palette-secondary-6-6;
$deferral-profile-6a: $topmenu-active-background;
$deferral-profile-7: $palette-secondary-6-7;

$deferral-enrollment-1: $palette-secondary-7-1;
$deferral-enrollment-1a: $palette-text;
$deferral-enrollment-2: $palette-secondary-7-2;
$deferral-enrollment-3: $palette-secondary-7-3;
$deferral-enrollment-4: $palette-secondary-7-4;
$deferral-enrollment-4a: $topmenu-active-background;
$deferral-enrollment-5: $palette-secondary-7-5;
$deferral-enrollment-6: $palette-secondary-7-6;
$deferral-enrollment-7: $palette-secondary-7-7;

$deferral-setup-1: $palette-secondary-3-1;
$deferral-setup-1a: $palette-text;
$deferral-setup-1b: $topmenu-active-background;
$deferral-setup-2: $palette-secondary-3-2;
$deferral-setup-3: $palette-secondary-3-3;
$deferral-setup-4: $palette-secondary-3-6;
$deferral-setup-4a: $topmenu-active-background;
$deferral-setup-5: $palette-secondary-3-5;
$deferral-setup-6: $palette-secondary-3-6;
$deferral-setup-7: $palette-secondary-3-7;

$deferral-fund-1: $palette-secondary-1-1;
$deferral-fund-1a: $palette-text;
$deferral-fund-1b: $topmenu-active-background;
$deferral-fund-2: $palette-secondary-1-2;
$deferral-fund-3: $palette-secondary-1-3;
$deferral-fund-4: $palette-secondary-1-6;
$deferral-fund-4a: $topmenu-active-background;
$deferral-fund-5: $palette-secondary-1-5;
$deferral-fund-6: $palette-secondary-1-6;
$deferral-fund-7: $palette-secondary-1-7;

$deferral-plan-1: $palette-secondary-4-1;
$deferral-plan-1a: $palette-text;
$deferral-plan-1b: $topmenu-active-background;
$deferral-plan-2: $palette-secondary-4-2;
$deferral-plan-3: $palette-secondary-4-3;
$deferral-plan-4: $palette-secondary-4-6;
$deferral-plan-4a: $topmenu-active-background;
$deferral-plan-5: $palette-secondary-4-5;
$deferral-plan-6: $palette-secondary-4-6;
$deferral-plan-7: $palette-secondary-4-7;

$deferral-participant-1: $palette-secondary-2-1;
$deferral-participant-1a: $palette-text;
$deferral-participant-1b: $topmenu-active-background;
$deferral-participant-2: $palette-secondary-2-2;
$deferral-participant-3: $palette-secondary-2-3;
$deferral-participant-4: $palette-secondary-2-6;
$deferral-participant-4a: $topmenu-active-background;
$deferral-participant-5: $palette-secondary-2-5;
$deferral-participant-6: $palette-secondary-2-6;
$deferral-participant-7: $palette-secondary-2-7;

$deferral-process-1: $palette-secondary-5-1;
$deferral-process-1b: $topmenu-active-background;
$deferral-process-2: $palette-secondary-5-2;
$deferral-process-2a: $palette-text;
$deferral-process-3: $palette-secondary-5-3;
$deferral-process-4: $palette-secondary-5-4;
$deferral-process-5: $palette-secondary-5-7;
$deferral-process-5a: $topmenu-active-background;
$deferral-process-6: $palette-secondary-5-6;
$deferral-process-7: $palette-secondary-5-7;

$deferral-utility-1: $palette-secondary-6-1;
$deferral-utility-1b: $topmenu-active-background;
$deferral-utility-2: $palette-secondary-6-2;
$deferral-utility-3: $palette-secondary-6-3;
$deferral-utility-3a: $palette-text;
$deferral-utility-4: $palette-secondary-6-6;
$deferral-utility-5: $palette-secondary-6-5;
$deferral-utility-6: $palette-secondary-6-6;
$deferral-utility-6a: $topmenu-active-background;
$deferral-utility-7: $palette-secondary-6-7;

$deferral-asset-1: $palette-secondary-7-1;
$deferral-asset-1a: $palette-text;
$deferral-asset-1b: $topmenu-active-background;
$deferral-asset-2: $palette-secondary-7-2;
$deferral-asset-3: $palette-secondary-7-3;
$deferral-asset-4: $palette-secondary-7-6;
$deferral-asset-4a: $topmenu-active-background;
$deferral-asset-5: $palette-secondary-7-5;
$deferral-asset-6: $palette-secondary-7-6;
$deferral-asset-7: $palette-secondary-7-7;

$deferral-enrollment-button: #d0d0d0;
$deferral-enrollment-button1: $topmenu-active-background;
$deferral-enrollment-button2: $topmenu-active-background;
$deferral-enrollment-helptext: #afb42b;

$text-menu: $palette-primary-dark-1;
$navbar-brand-minimized-logout-image: url("../../public/img/bkgdPark.jpg") !default;
